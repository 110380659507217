.article-item {
    margin-bottom: 30px;
    padding: 15px 10px;
    height: 210px;
}

.articleItem .headWrap {
    color: white;
    background: rgba(9, 41, 83, 0.7);
    padding: 10px 30px;
   // position: absolute;
    top: 0px;
    margin-right: 15px;
	margin-bottom: 15px;
}

.articleItem .headWrap h2 {
    color: white !important;
}
