.search-results {
    ul {
      // list-style: none;
       // margin-left: 0;
       margin-top: 15px;
    }

    li {
        padding: 3px 0;
    }

}