
.home-news {
    font-size: .8em;
    background: $green url(../images/home-newsletter-block.jpg) no-repeat;
    background-position: 10px 13px;
    padding: 15px;
    min-height: 286px;
}

.home-news .copy {
    padding: 0 0 0 135px;
    min-height: 140px;
}

.home-news p {
    color: #fff;
    font-size: .8rem;
    margin-bottom: .8em;
}

.home-news .btn-default {
    box-shadow: 2px 2px 2px #333;
}

.home-news h4 {
    color: #fff;
    font-weight: 300;
}

.home-page .whats-happening {
    font-weight: 300;
    font-size: 1.6rem;
    background: #001040;
    color: #fff;
    padding: 10px;
}

.whats-happening-home {
    margin-left: 0;

    > div {
        padding-left: 0;
    }
}

.home-article {
    margin-top: 15px;
    display: flex;
}

.home-article .content {
    min-height: 175px;
}

.home-article .btn {
    margin-top: -20px;
}

.home-article .content {
    flex-direction: column;
    max-width: 50%;
}

.home-article .intro {
    flex-grow: 1;    
}
 
.home-article p,
.home-article h5 {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 15px;
}

.home-article h5 {
    font-weight: bold;
}

.home-article .image {
    margin-right: 10px;
    width: 30%;
}

.home-article .image img {
    border: 3px $grey solid;
}

.home-member {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1.1em;
}

.home-member .logo {
    margin-right: 10px;
    width: 30%;
}

.home-member .logo img {
    border: 1px #eee solid;
}

.home-member .content {
    max-width: 50%;
}

.home-member h5 {
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: .1rem;
}

.home-member h6 {
    flex-grow: 1;
    margin-bottom: 15px;
}



input.mailing-list {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    font-size: 1.3em;
}

.home-newsletter-gallery {
    @media(min-width: 768px) {
        display: flex;
    }    
}

.home-newsletter-block {        
    @media(min-width: 768px) {
        width: 50%;
        padding-right: 15px;
    }

    input.mailing-list {
        display: block
    }

}

.home-gallery-block {    
    @media(min-width: 768px) {
        width: 50%;
        padding-left: 15px;
    }

    .content {
        margin-top: 4px;
        background: $blue;
        color: #fff;
        padding: .7em;
        font-size: .9em;
    }

    .view {
        float: right;
        padding: 4px 20px;
    }

    .view:hover {
        background: $green;
    }
}

/*@media(max-width: 1440px) {
    .slick-slide .left {
        padding-top: 20% !important;
    }
}

@media(max-width: 1200px) {
    .slick-slide .left {
        padding-top: 10% !important;
    }
}

@media(max-width: 1024px) {
    .slick-slide .left {
        padding-top: 10% !important;
    }
}*/

@media(max-width: 768px) {
    .slick-slide .left {
    }

    .slick-slide .right {
        margin-top: 30%;
        width: 100% !important;
    }

    .home-slider-mobile .slick-slide {
        height: 450px;
    }
}