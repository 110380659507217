.content-page {
    .banner {
        position: relative;        
    }

    .banner img {
        display: block;
    }

    .banner h2 {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: 0;
        color: white;
        background: rgba(9,41,83,.7);
        padding: 5px 20px 10px 15px;
    }

    h3 {
        margin-bottom: 20px;
    }

    .content {
        padding: 15px;
    }

    p {
        margin-bottom: 15px;
        color: $blue;
        font-size: .8em;
    }

}