.breadcrumb {
    padding: 0;
    margin-left: 0;
    margin-bottom: 15px;
    background-color: #fff;

    a {
        font-size: .9em;
        font-weight: 400;
    }

    .active {
        color: $purple;
    }
}