html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    background: transparent;
    vertical-align: baseline;
    font-size: 100%;
}

body {
    line-height: 1;
    color: $blue;
    padding-top: 230px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

nav ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;
    background: transparent;
    vertical-align: baseline;
    font-size: 100%;
    color: $blue;

    &:hover {
        color: $green;
    }
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background-color: #ff9;
    color: #000;
    font-weight: bold;
    font-style: italic;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

hr {
    display: block;
    margin: 1em 0;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
}

input,
select {
    vertical-align: middle;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.container {
    max-width: 1170px;
    padding: 10px 30px 10px 15px;

    @media(min-width:768px) {
        padding: 10px 0;
    }
}

.row {
    margin-top: 0;
    margin-bottom: 0;
}

.dark {
    background: #1c1c1e;
}

.dark * {
    color: #fff;
}

.dark a,
.dark a:visited {
    color: #f45376;
}

.dark a:hover,
.dark a:visited:hover {
    text-decoration: underline;
}

.dark a.button {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0);
    color: #fff;
}

.dark a.button:hover {
    background: #fff;
    color: #000;
}

.dark a.button.fill {
    background: #fff;
    color: #000;
}

.dark a.button.fill:hover {
    background: rgba(255, 255, 255, 0.5);
    color: #fff;
}

.dark code,
.dark pre {
    background-color: rgba(248, 248, 248, 0.1);
}

.dark+.triangle:before {
    border-color: transparent transparent transparent #1c1c1e;
}

.light {
    background: #fff;
}

.light * {
    color: #000;
}

.light a,
.light a:visited {
    color: #f45376 !important;
}

.light a:hover,
.light a:visited:hover {
    text-decoration: underline;
}

.light a.button {
    border: 1px solid #000;
    background: transparent;
    color: #000;
}

.light a.button:hover {
    background: #000;
    color: #fff;
}

.light a.button.fill {
    background: #000;
    color: #fff;
}

.light a.button.fill:hover {
    background: rgba(0, 0, 0, 0.2);
    color: #000;
}

.light code,
.light pre {
    background-color: #f8f8f8;
}

.light+.triangle:before {
    border-color: transparent transparent transparent #fff;
}

.yellow {
    background: #FFCC01;
}

.yellow * {
    color: #000;
}

.yellow a,
.yellow a:visited {
    color: #f42146;
}

.yellow a.button {
    border: 1px solid #000;
    background: transparent;
    color: #000;
}

.yellow a.button:hover {
    background: #000;
    color: #FFCC01;
}

.yellow a.button.fill {
    background: #000;
    color: #FFCC01;
}

.yellow a.button.fill:hover {
    background: rgba(0, 0, 0, 0.2);
    color: #000;
}

.yellow+.triangle:before {
    border-color: transparent transparent transparent #FFCC01;
}

.blue {
    background: #51c1f5;
}

.blue * {
    color: #fff;
}

.blue a,
.blue a:visited {
    text-decoration: underline;
}

.blue a:hover,
.blue a:visited:hover {
    text-decoration: none;
}

.blue a.button {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0);
    text-decoration: none;
}

.blue a.button:hover {
    background: #fff;
    color: #51c1f5;
}

.blue a.button.fill {
    background: #fff;
    color: #51c1f5;
}

.blue a.button.fill:hover {
    background: rgba(255, 255, 255, 0.5);
    color: #fff;
}

.blue+.triangle:before {
    border-color: transparent transparent transparent #51c1f5;
}

.purple {
    background: #C2357B;
}

.purple * {
    color: #fff;
}

.purple a,
.purple a:visited {
    text-decoration: underline;
}

.purple a:hover,
.purple a:visited:hover {
    text-decoration: none;
}

.purple a.button {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0);
    text-decoration: none;
}

.purple a.button:hover {
    background: #fff;
    color: #C2357B;
}

.purple a.button.fill {
    background: #fff;
    color: #C2357B;
}

.purple a.button.fill:hover {
    background: rgba(255, 255, 255, 0.5);
    color: #fff;
}

.purple+.triangle:before {
    border-color: transparent transparent transparent #C2357B;
}

.round {
    border-radius: 50%;
}

.text--center {
    text-align: center;
}

.text--center .seperator {
    margin-right: auto;
    margin-left: auto;
}

.center,
.center img {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

body {
    font-family: "Lato", sans-serif;
    line-height: 1.4;
}

h1 {
    font-weight: 900;
}

h2,
h3,
h4 {
    font-weight: 300;
    color: $blue;
}

h1 {
    letter-spacing: -.2px;
    font-size: 2rem;
}

h2 {
    font-size: 2.2rem;
    font-weight: 300;
    margin-top: .2em;
    margin-bottom: .5em;
}

h3 {
    font-size: 2.3rem;
    margin-top: .3em;
    margin-bottom: .2em;
}

h4 {
    font-size: 1.1rem;
    margin-bottom: 3px;
}

h5 {
    font-size: 2rem;
    font-weight: 100;
    margin: 0;
    color: $blue;
}

h5 a {
    font-size: inherit;
    font-family: inherit !important;
}

strong {
    font-weight: 900;
}

p,
blockquote,
li,
a {
    font-size: .9rem;
    line-height: 1.4;
    letter-spacing: 0.2px;
    color: $blue;
}


a,
a:visited {
    text-decoration: none;
    width: auto;
    white-space: nowrap;
    font-weight: 700;
}

.level-2 a:hover {
    text-decoration: none;
}

blockquote {
    color: #2c2c2c;
    font-size: 1.2rem;
}

blockquote:before,
blockquote:after {
    display: inline-block;
    padding: 0 2px;
    font-size: 1.4rem;
    content: '"';
}

a.button {
    border-radius: 3px;
    padding: 8px 18px !important;
    margin-top: 20px;
    font-family: "Lato", sans-serif !important;
    font-size: 1rem;
    background: transparent;
    transition: all .2s ease-in-out;
}

a.button:hover {
    background: #000;
    color: #fff;
    text-decoration: none !important;
}

a.button.fill {
    background: #000;
    color: #fff;
}

a.button.fill:hover {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-decoration: none;
}

html,
body {
    width: 100%;
    overflow-x: hidden;
}

body {
    background: transparent;
    min-height: 100%;
    min-width: 100%;
    font-size: 17px;
}

*[role="content"] {
    position: relative;
    min-height: calc(100vh - 486px);
}

@media (max-width: 768px) {
    header {
        padding: 30px 0;
    }
}

header {
    background: #ffffff;
    top: 0;
    width: 100%;
    z-index: 1000;
    position: fixed !important;
    right: 0;
    left: 0;
}

header .container {
    max-width: 100%;
    padding: 0;
    position: relative;
}

header .row {
    margin: 0;
    margin-left: -15px;
    margin-right: -15px;
}

.brand {
    /*margin: 0 auto;*/
    margin-top: 15px;
    width: 441px;
   margin-left: -150px;
    height: 200px;
    background: url(../STA_Logo_desktop.png) no-repeat;
}

#toggle {
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-40%);
    -ms-transform: translateY(-40%);
    transform: translateY(-40%);
}

.toggle {
    position: relative;
    z-index: 999;
    display: inline-block;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.toggle .cross {
    transition-duration: 0s;
    transition-delay: .1s;
}

.toggle .cross span:before {
    transition-property: margin, -webkit-transform;
    transition-property: margin, transform;
    transition-duration: .1s;
    transition-delay: .1s, 0;
}

.toggle .cross span:after {
    transition-property: margin, -webkit-transform;
    transition-property: margin, transform;
    transition-duration: .1s;
    transition-delay: .1s, 0;
}

.toggle .cross.open span {
    background-color: transparent;
    transition-delay: .1s;
}

.toggle .cross.open span:before {
    margin-top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition-delay: 0, .1s;
    background-color: #c6c5cd;
}

.toggle .cross.open span:after {
    margin-top: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition-delay: 0, .1s;
    background-color: #c6c5cd;
}

.toggle span {
    margin: 0 auto;
    position: relative;
    z-index: 999;
    top: 12px;
    width: 25px;
    height: 2px;
    background-color: #fff;
    display: block;
}

.toggle span:before,
.toggle span:after {
    position: absolute;
    content: '';
    width: 25px;
    height: 2px;
    background-color: #fff;
    display: block;
}

.toggle span:before {
    margin-top: -7px;
}

.toggle span:after {
    margin-top: 7px;
}

nav {
    z-index: 999;
    background: rgba(0, 0, 0, 0.95);
    text-align: right;
    left: 0px;
    right: 0px;
    display: block;
    position: relative;
    transition: height 300ms ease-in-out;
}

nav>ul {
    padding: 20px;
    position: relative;
    display: inline-table;
    width: 100%;
}

nav>ul li {
    position: relative;
    font-family: "Lato", sans-serif;
}

nav>ul li a,
nav>ul li span {
    cursor: pointer;
    display: inline-block;
    padding: 18px;
    font-size: 20px;
    font-weight: 500;
    color: $blue;
}

nav>ul li a:hover,
nav>ul li span:hover {
    color: $blue;
}

.open > ul li a:hover,
.open > ul li span:hover {
    color: white;
}

nav>ul li span {
    cursor: default;
}

nav>ul li:after {
    content: "";
    clear: both;
    display: block;
}

nav>ul li.selected>a,
nav>ul li.selected span {
    color: $purple;
}

nav li>ul {
    position: absolute;
}

nav li>ul li {
    float: none;
    display: block;
    position: relative;
}

nav li>ul li a {
    font-size: 17px;
    padding: 15px 15px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
}

nav li>ul li a:hover {
    background: $purple;
    color: #ffffff;
}

nav li>ul>li>ul {
    left: 100%;
    top: 0;
    display: none;
}

.blogarchive div[class^="col-"] {
    margin-bottom: 20px !important;
}

.blogarchive .equal {
    width: 100%;
}

.blogarchive a {
    white-space: normal;
    transition: all 200ms ease;
    padding: 12px 18px;
    background: rgba(0, 0, 0, 0.08);
    display: block;
}

.blogarchive a:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.05);
}

.blogarchive a:hover p {
    color: #2a2a2a;
}

.blogarchive .date {
    font-size: .9rem;
    text-align: right;
    margin-bottom: 5px;
}

.blogarchive h2 {
    font-size: 1.2rem;
    font-family: "Lato", sans-serif;
    transition: all 200ms ease;
}

.blogarchive p {
    font-size: 1rem;
    color: #7f7d7d;
    transition: all 200ms ease;
}

footer ul {
    margin: 15px auto 0;
    padding-bottom: 4px;
    text-align: center;
    border-bottom: 1px solid $blue;
}

footer ul li {
    position: relative;
    display: inline-block;
    text-align: left;
    padding: 0 10px;
    margin: 0;
    line-height: 1;

    @media(min-width:768px) {        
        padding: 0 20px;
    }
}

footer a {
    color: $blue;    
    font-size: .9em;
    font-weight: 400;

    @media(min-width:768px) {        
        font-size: 1.1em;
    }
}

footer a:hover {
    color: $purple;
    text-decoration: none;
}

footer .selected a {
    color: $purple;
}

footer .copy {
    text-align: center;    
    padding: 20px 0;
    font-size: .8em;

    @media(min-width:768px) {
        padding: 40px 0;
        font-size: 1em;
    }
}

ul {
    margin-left: 20px;
}

img {
    max-width: 100%;
    height: auto;
}

blockquote {
    padding: 10px;
    text-align: center;
    position: relative;
    font-size: 1.3rem;
}

blockquote+* {
    padding: 30px 10px;
}

*+ul {
    margin-bottom: 30px;
}

.equal {
    display: table;
    table-layout: fixed;
}

.equal .vcenter {
    display: table-cell;
    vertical-align: middle;
}

.full {
    margin: 0;
    padding: 0 !important;
    overflow: hidden;
}

.full * {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 0;
}

.full .wide {
    width: 100%;
    height: auto;
}

.small {
    font-size: .85rem;
}

code,
pre {
    overflow: auto;
    padding: 6px 10px;
    border-radius: 3px;
    background-color: rgba(248, 248, 248, 0.3);
    font-size: .9rem;
    font-family: "Courier 10 Pitch", Courier, monospace;
    line-height: 2.3;
}

.gallery .row>div {
    margin-bottom: 2%;
}

.gallery img {
    margin: 0;
}

.video-wrapper {
    margin: 30px auto;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// .breadcrumb {
//     background: rgba(0, 0, 0, 0.6);
//     transition: all 200ms ease-in-out;
// }

// .breadcrumb a,
// .breadcrumb a:visited {
//     color: #fff;
//     font-size: 1rem;
// }

// .breadcrumb:hover {
//     background: black;
// }

#google_translate_element {
    float: right;
}

.under-nav {
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.under-nav .search-container {
    align-self: center;
}

.under-nav .search-container input, button {
    display: block;
    float: left;
}

.under-nav .col.weather-block {
    flex-grow: 1;
    min-width: 130px;
}

.under-nav .col.sign-up-block {
    flex-grow: 1;
    /*margin-left: auto;*/
    /*text-align: right;*/
    min-width: 130px;
}

.search-box input {
    height: 50px;
    width: 75%;
    max-width: 370px;
    border: 1px solid #7c7c7c;
    padding: 15px;
    color: #7c7c7c;
    font-size: 18px;
}

.search-box button {
    height: 50px;
    width: 25%;
    max-width: 115px;
    background: $purple;
    color: #ffffff;
    border: none;
    text-transform: uppercase;
    text-align: center;
}


/*weather & newsletter*/

.weather-block,
.sign-up-block {
    display: block;
    color: #fff;
    white-space: normal;
    /*transform: translateX(25%);*/
    &:hover {
        color: white;
    }
}

.weather-block .details,
.sign-up-block .details {
    height: 102px;
    background: $blue;
    max-width: 145px;
    font-size: 27px;
    font-weight: 400;
    padding: 10px 0;
    position: absolute;
    right: 30px;
}

.weather-block .more-info, .sign-up-block .sign-up {
    position: absolute;
    right: 0px;
}

.weather-block .details .image {
    margin: 0 auto;
    width: 50px;
    height: 39px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.sign-up-block .details .image {
    margin: 0 auto;
    width: 35px;
    height: 26px;
    background: url(../images/envelope.png);
}

.sign-up-block .details {
    background: #003939;
    font-size: 17px;
    line-height: 21px;
    font-weight: 300;
    padding: 15px 10px;
    text-align: center;
    color: #fffff1;
}

.sign-up-block .details .copy {
    margin-top: 10px;
}

.weather-block .details .image.sunny,
.weather-block .details .image.mostly-sunny {
    background-image: url(../images/sunny.png);
}

.weather-block .details .image.partly-cloudy {
    background-image: url(../images/partly-cloudy.png);
}

.weather-block .details .image.mostly-cloudy {
    background-image: url(../images/weather_cloudy.png);
}

.weather-block .details .image.rain {
    background-image: url(../images/weather_heavy_rain.png);
}

.weather-block .details .temp {
    margin: 3px auto 0;
    text-align: center;
}

.weather-block .more-info,
.sign-up-block .sign-up {
    background: $purple;
    max-width: 30px;
    padding-left: 5px;
    padding-right: 5px;
}

.weather-block .more-info span,
.sign-up-block .sign-up span {
    writing-mode: tb-rl;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg) translateY(12%);
    white-space: nowrap;
    display: block;
    bottom: 10px;
    width: 20px;
    height: 102px;
    color: #ccd7d7;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 13px;
}

.sign-up-block .sign-up span {
    transform: rotate(180deg) translateY(23%);
}

.hidden {
    display: none;
}

.btn-default {
    display: inline-block;
    color: #fff;
    border: none;
    padding: 5px 20px;
    background: $purple;
    border-radius: 0;
    text-transform: uppercase;
    line-height: 1.1;
    font-size: 14px;
}

.btn-default:hover {
    background: $blue;
    color: #fff;
}

.panel-group .panel {
    border-radius: 0;
    box-shadow: none;
}

.panel-default {
    border: none;
}

.panel-body {
    padding: 0 0 15px;
}

.panel-default>.panel-heading {
    background: $blue;
    color: #fff;
    border-radius: 0;
}

.panel-title {
    position: relative;
}

.panel-title a {
    font-size: 1.1em;
    width: 100%;
    display: block;
    font-weight: 300;
}

.panel-title a:focus,
.panel-title a:hover {
    text-decoration: none;
    color: #999;
}

.panel-title a:before {
    content: '';
    -webkit-clip-path: polygon(100% 0, 0 0, 48% 100%);
    clip-path: polygon(100% 0, 0 0, 48% 100%);
    position: absolute;
    right: 0;
    vertical-align: middle;
    height: 15px;
    background: #fff;
    width: 15px;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.panel-title a.collapsed:before {
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
}

.panel-default>.panel-body {
    border: none;
}

.panel-body>div {
    padding-left: 0;
}

.ads {
    background: #ebebeb;
}

.level-2 {
    padding-top: 0px;
}

.level-4 {
    margin-left: 0px;
    padding-top: 0px;
}

input.closed {
    height: 0;
    padding: 0;
    border: none;
}

input.open {
    height: auto;
    padding: 10px;
    border: none;
}

@media (min-width: 768px) {
    .dark,
    .light,
    .yellow,
    .blue,
    .purple {
        padding: 80px 0;
    }
    .dark img,
    .light img,
    .yellow img,
    .blue img,
    .purple img {
        padding: 0;
    }
    .blogarchive a {
        background: rgba(0, 0, 0, 0.02);
    }
    .blogarchive a {
        min-height: 250px;
        overflow: hidden;
    }
    .blogarchive a:hover h2 {
        -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        transform: translateY(-3px);
    }
    // .breadcrumb {
    //     padding: 4px 8px;
    //     position: absolute;
    //     left: 40px;
    //     top: 40px;
    // }
    // .breadcrumb a {
    //     font-size: 1.2rem;
    // }
}

@media (min-width: 992px) {
    header {
        padding: 0 0 15px;
    }
    header .container {
        max-width: 960px;
        padding: 0 0;
    }
    #toggle {
        display: none;
    }
    nav {
        margin: 0 auto;
        background: none;
        width: 100%;
    }
    nav>ul {
        padding-right: 0;
        width: auto;
    }
    nav>ul li {
        float: left;
    }
    nav>ul li a,
    nav>ul li span {
        font-size: 17px;
    }
    nav>ul>li:last-child a {
        padding-right: 0;
    }
    nav li>ul {
        left: 0;
        top: 75%;
        display: none;
        white-space: nowrap;
        height: auto;
        margin-bottom: 0;
        margin-left: 10px;
        text-align: left;
        background: #ffffff;
        padding: 5px 0 0;
    }
    nav li>ul li a {
        padding: 5px 15px;
        font-size: 15px;
        color: $blue;
        width: 100%;
    }
    nav .has-child:hover > .sublevel {
        display: block;
        box-shadow: 2px 2px 2px #ccc;
    }
    .dark .row div[class^="col-"],
    .light .row div[class^="col-"],
    .yellow .row div[class^="col-"],
    .blue .row div[class^="col-"],
    .purple .row div[class^="col-"] {
        margin-bottom: 20px;
    }

    .sign-up-block .details {
        padding: 15px 5px;
    }
}

@media (max-width:641px) {
    .brand {
        margin-top: -50px;
        margin-left: 10px;
        width: 125px;
        height: 140px;
        background-size: contain;
    }
}

@media (max-width: 992px) {
    body {
        width: 100vw;
        overflow-x: hidden;
    }
    nav {
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0;
        transition: -webkit-transform 300ms ease-in-out, opacity 300ms ease-in-out;
        transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
        text-align: center;
        position: absolute;
    }
    nav.open {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
    nav>ul {
        margin: 0;
    }
    nav>ul li:first-child a {
        padding: 10px 25px;
    }
    nav>ul li a,
    nav>ul li span {
        padding: 25px 25px 10px 25px;
        color: rgba(255, 255, 255, 0.6);
    }
    nav > ul li.selected > a,
    nav > ul li.selected span {
        color: #fff;
        font-weight: 700;
        width: 100%;
        background: #99ca44;
    }
    nav > ul li.selected ul {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        height: 120%;
        background-color: white;
    }
    nav li>ul {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        position: relative;
        text-align: center;
        transition: all 300ms ease-in-out;
        height: 0;
        margin: 0;
    }

    .slick-slide img {
        height: 100%;
    }
}

@media (max-width: 992px) and (max-width: 992px) {
    nav>ul li.selected ul li a {
        color: $blue;
        font-weight: 500;
    }
}

@media (max-width: 768px) {
    // .breadcrumb {
    //     text-align: center;
    //     padding: 20px 0;
    //     background: black;
    // }
    }
 
@media (min-width: 1170px) {
    header .container {
        max-width: 1170px;
    }
}

@media (max-width: 1200px) {

    .under-nav {
        position: absolute;
        right: -25px;
        top: 80px;
    }

    .under-nav .search-container {
        position: absolute;
        right: 365px;
        top: 52px;
    }
}

@media (max-width: 991px) {
    .under-nav {
        position: absolute;
        top: -175px;
        left: -30%;
        width: 100%;
    }

    .under-nav .search-container {
        position: absolute;
        right: 0px;
        top: 115px;
    }

    .toggle {
        height: 75px;
        width: 25%;
        max-width: 115px;
        background: #99ca44;
        color: #ffffff;
        border: none;
        text-transform: uppercase;
        text-align: center;
        top: 69%;
    }

    .toggle h3 {
        font-size: 25px;
        color: #fff;
    }
}

@media (max-width: 767px) {
    header {
        height: 230px; 
    }

    .mobile-right {
        display: block !important;
    }

    #google_translate_element {
        display: none;
    }

    .toggle {
        background: transparent;
        top: 75px !important;
    }

    .toggle span, .toggle span:before, .toggle span:after {
        width: 60px;
        background-color: #000;
    }

    .toggle h3 {
        display: none;
    }

    .under-nav {
        position: inherit;
        top: 0px;
        right: 0px;
        left: 0;
    }

    .weather-block {
        display: none;
    }

    .search-container {
        top: 0px !important;
        width: 100%;
    }

    .search-container .search-box {
        max-width: 550px;
    }

    .search-container .search-box > button {
        position: absolute;
        right: 15px;
        max-width: 550px;
    }

    .search-container .search-box > input {
        position: absolute;
        max-width: 550px;
    }

    .sign-up-block {
        position: absolute;
        right: 0px;
        top: 60px;
        width: 100%;
    }

    .sign-up-block .details {
        width: 75%;
        max-width: 550px;
        height: 50px;
        left: 15px;
    }

    .sign-up-block .sign-up {
        width: 25%;
        position: absolute;
        right: 15px;
        height: 50px;
        max-width: 550px;
    }

    .sign-up-block .sign-up > span {
        transform: none;
        writing-mode: horizontal-tb;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        margin: 0 auto;
        width: 70px;
        padding-top: 10px;
        height: auto !important;
    }

    .sign-up-block .details .image, .copy {
        float: left;
    }

    .sign-up-block .details .image {
        margin: -2px 15px 0px 10px;
    }

    .sign-up-block .details .copy {
        margin-top: 0px;
        font-weight: 500;
        width: 70%;
        height: 20px;
        text-align: left;
    }

    .brand {
        margin-top: -20px;
        margin-left: 15px;
        width: 300px;
        height: 100px;
        background: url(../images/STA_Logo_mobile.png) no-repeat;
    }

    nav.open {
        top: 140px !important;
    }
}

@media (max-width: 460px) {
    .brand {
        width: 100px;
    }

    .sign-up-block .details .copy {
        display: none;
    }

    .sign-up-block .sign-up > span {
        padding-top: 13px;    
    }
}

@media (max-width: 767px) {
    .nav-row .level-2 li:nth-child(6) {
    margin-right: 0px;
    }
}

.goog-te-gadget-simple {
    width: 150px;
    height: 25px;
    border: none !important;
}

.goog-te-gadget-simple span {
    font-weight: 100 !important;
    border: none !important;
}

.goog-te-menu-value {
    font-size: 13px;
}

.goog-te-menu-value img {
    margin-left: -1px !important;
}

/* EDITOR PROPERTIES - PLEASE DON'T DELETE THIS LINE TO AVOID DUPLICATE PROPERTIES */