.modal-dialog {
    z-index: 10000;  

    h4 {
        color: white;
    }  

    input.mailing-list { 
        font-size: 1em;
        color: $blue;
    }

    .btn-default {
        padding: 12px 30px;
    }

    .close {
        color: white;
        text-shadow: none;
        opacity: 1;
    }
}


.modal-content {
    background: $green;
    color: white;    
}