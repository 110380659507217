.slick-slide {
    position: relative;
}

@media (max-width: 320px) {
    .slick-slide .left {
        padding-bottom: 7%;
    }
}

@media (max-width: 375px) {
    .slick-slide .left {
        margin-bottom: 15% !important;
        font-size: 10px !important;
    }
}

@media (max-width: 430px) {
    .slick-slide .left {
        margin-bottom: 10%;
        font-size: 14px;
    }
}

@media (max-width: 640px) {
    .slick-slide h1 {
        font-size: 2em !important;
    }
}

@media (max-width: 768px) {
    .slick-slide {
        height: 500px;
    }

    .slick-slide h1 {
        font-size: 3em !important;
    }

    .slick-slide .left {
        bottom: 50% !important;
    }
}

@media (max-width: 992px) {
    .slick-slide {
        height: 350px;
    }

    .slick-slide .left {
        bottom: 30%;
    }
}

@media (min-width: 993px) {
    .slick-slide .left {
        bottom: 12%;
    }
}

.slick-slide .container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    height: 100%;
    max-width: 1170px;
    display: flex;
    padding: 0;
}

.slick-slide .left {
    width: 65%;
    position: absolute;
}

.slick-slide .right {
    background: #444f5f;
    width: 35%;
    color: #fff;
    padding: 3% 2%;
    position: absolute;
    right: 0px;
    height: 100%;
}

.slick-slide p {
    color: #fff;
    font-weight: 300;
    font-size: .9rem;
    margin-bottom: 15px;
}

.slick-slide h1 {
    color: #fff;
    font-weight: 400;
    font-size: 6em;
    line-height: 1;
    background: rgba(10, 35, 77, .6);
    padding: 10px;
    width: 60%;
    text-transform: uppercase;
}

.slick-slide h2 {
    color: #fff;
    font-weight: 300;
    font-size: 1.3em;
    line-height: 1;
    background: #99ca44;
    padding: 10px;
    width: 60%;
}

.slick-slide .btn {
    border: 1px solid #fff;
    padding: 5px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    margin-top: 15px;
}

.slick-dots {
    bottom: 20px;
    text-align: left;
    max-width: 1170px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-49%);
}

.slick-dots li button:before {
    font-size: 22px;
    opacity: 1;
    color: #d6d6d6;
    text-shadow: 0 2px 1px #111;
}

.slick-dots li.slick-active button:before {
    font-size: 18px;
    border-radius: 9px;
    border: 2px solid #fff;
    opacity: 1;
    color: #99ca44;
    text-shadow: none;
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.home-gallery-slider {
    margin-bottom: 0;
    max-height: 239px;
    overflow: hidden;

    .slick-track {
        max-height: 203px;
    }
}

.home-gallery-slider .slick-prev,
.home-gallery-slider .slick-next,
.fancybox-prev span,
.fancybox-next span {
    left: 0;
    z-index: 999;
    background: rgba(255, 255, 255, .4);
    padding: 2em;
    width: 18%;
    height: 25%;
}

.fancybox-prev span,
.fancybox-next span {
    transform: translateY(-45%);
}

.fancybox-next span {
    left: auto;
    right: 0;
}

.home-gallery-slider .slick-next {
    right: 0;
    left: auto;
}

.home-gallery-slider .slick-prev:before,
.home-gallery-slider .slick-next:before,
.fancybox-prev span:before,
.fancybox-next span:before {
    content: '';
    -webkit-clip-path: polygon(75% 0%, 56% 47%, 75% 100%, 75% 100%, 0 47%, 75% 0);
    clip-path: polygon(75% 0%, 56% 47%, 75% 100%, 75% 100%, 0 47%, 75% 0);
    position: absolute;
    right: 0;
    vertical-align: middle;
    height: 25px;
    background: #fff;
    width: 35px;
    top: 50%;
    left: 30%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.home-gallery-slider .slick-next:before,
.fancybox-next span:before {
    transform: rotate(180deg);
    top: 35%;
    left: 25%;
}

.fancybox-next span:before {
    top: 43%;
}

.fancybox-title-float-wrap {
    background: rgba(255,255,255,.4);
    padding: 20px;
    width: 97%;
    right: auto;
    margin-bottom: 0;

    .child {
        margin-right: 0;
        background: none;
        font-size: 1.2em;
        color: #fff;
        letter-spacing: .05em;
        font-weight: 300;
        text-shadow: none;
    }
}

.gallery-page .content {
    display: flex;
    flex-flow: row wrap;

    .fb {
        margin: 0 15px 15px;
        width: 25%;
    }
}

@media (max-width: 768px) {
    .slick-dots {
        top: 20px;
        text-align: right;
        max-width: 1170px;
        margin: 0 auto;
        margin-left: -50px;
        transform: translateX(-49%);
    }
}

@media (max-width: 640px) {
    .left h2 {
        display: none;
    }
}
