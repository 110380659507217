﻿
.event-main h6 {
    font-size: 22px;
    padding: 5px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-main p {
        height: 60px;
}

.event-main .dates {
    font-size: 14px;
    color: #888;
    padding-bottom: 5px;
}

.event-main .row .col-sm-4 {
    padding: 20px; 
}

.event-item .eventText {
    display: inline-block;
    margin: 0px 10px;
}

.event-item .first {
    margin-left: 0px;
}

.event-item img {
    float: left;
    margin: 0px 20px 10px 0px;
}

.event-item .headWrap {
    color: white;
    background: rgba(9, 41, 83, 0.7);
    padding: 10px 30px;
    margin-left: 10px;
   // position: absolute;
   	margin-bottom: 15px;
}

.event-item h2 {
    color: white;
}

.event-item .dates {
    font-size: 14px;
    padding-bottom: 5px;
    padding-left: 5px;
    color: white;
}


