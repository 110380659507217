.member-profile {
    h3 {
        font-size: 2.2rem;
    }

    h3 > span {
        font-size: 1.4rem;
        font-weight: 700;
    }

    h2 {
        left: 20px !important;
    }

    img {
        margin: 10px 20px;
    }
}
