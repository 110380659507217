﻿.contact-page {
    .address-container {
        padding-top: 60px;
    }

    .form-control {
        border-radius: 0;
    }

    label {
        font-size: .9rem;
    }
}
