.pagination {

    li a {
        color: $blue;
        font-size: .7rem;
    }

    li>a:focus,
    li>a:hover,
    li>span:focus,
    li>span:hover {
        color: white;
        background-color: $purple;
        border-color: $purple;
    }

    .active>a, 
    .active>a:focus, 
    .active>a:hover, 
    .active>span, 
    .active>span:focus,
    .active>span:hover {
        background: $blue;
        border-color: $blue;        
    }

    li:first-child>a, 
    li:first-child>span {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    li:last-child>a,
    li:last-child>span {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}